@import url("https://fonts.cdnfonts.com/css/google-sans");

body,
html {
    margin: 0;
    padding: 0;
    font-family: "Product Sans", sans-serif, serif;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none;
}
body::-webkit-scrollbar {
    display: none; /* 크롬, 사파리, 오페라, 엣지 */
}

figure {
    display: block;
    margin: 8px;
    text-align: center;
}

figure img {
    max-width: 50%;
    border-radius: 14px;
}

ul {
    outline: none !important;
}

.titlebar {
    -webkit-user-select: none;
    -webkit-app-region: drag;
    position: fixed;
    z-index: 100000;
}

.hide-scrollbar {
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none;
}
.hide-scrollbar::-webkit-scrollbar {
    display: none; /* 크롬, 사파리, 오페라, 엣지 */
}

.disabled-focus {
    box-shadow: none !important;
}

.cursor-default {
    cursor: default !important;
}

.unselectable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}

input::placeholder {
    font-weight: normal;
}

textarea::placeholder {
    font-weight: normal;
}

thead th {
    padding-bottom: 8px !important;
}

thead th input {
    margin-top: 5px !important;
}

tbody:before {
    content: "@";
    display: block;
    line-height: 8px;
    text-indent: -99999px;
}

.hover-border-button {
    outline: transparent 2px solid !important;
}
.hover-border-button:hover {
    outline: #01A982 2px solid !important;
}

.notistack-SnackbarContainer {
    bottom: 24px;
    right: 30px;
}

.notistack-MuiContent {
    border-radius: 14px;
}

.card {
    aspect-ratio: auto 1/1;
}

.hover-overlay-container {
    display: inline-block;
    position: relative;
}

.hover-overlay-container * {
    box-sizing: border-box;
    transition: all 0.45s ease;
}

.hover-overlay-container::before,
.hover-overlay-container::after {
    background-color: rgba(0, 0, 0, 0.45);
    /*border-top: 32px solid rgba(0, 0, 0, 0.2);*/
    /*border-bottom: 32px solid rgba(0, 0, 0, 0.2);*/
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    transition: all 0.3s ease;
    z-index: 1;
    opacity: 0;
    transform: scaleY(2);
}

.hover-overlay-container img {
    vertical-align: top;
    width: 100%;
    height: auto;
    backface-visibility: hidden;
}

.hover-overlay-container button {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.1em;
    opacity: 0;
    z-index: 2;
    transition-delay: 0.1s;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.hover-overlay-container:hover::before,
.hover-overlay-container:hover::after {
    transform: scale(1);
    opacity: 1;
}

.hover-overlay-container:hover > img {
    opacity: 0.9;
}

.hover-overlay-container:hover button {
    opacity: 1;
}

#form-file-upload {
    max-width: 100%;
    text-align: center;
    position: relative;
}

#input-file-upload {
    display: none;
}

#label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-radius: 16px;
    border-style: dashed;
    background-color: transparent;
}

#label-file-upload.dark {
    background-color: #1C1C1C;
    border-color: #FFFFFF5C;
}

#label-file-upload.light {
    background-color: #FFFFFF;
    border-color: #0000005C;
}

#label-file-upload.drag-active-dark {
    background-color: #222222;
    border-color: #FFFFFFB8;
}

#label-file-upload.drag-active-light {
    background-color: #F7F7F7;
    border-color: #000000B8;
}

.upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    background-color: transparent;
}

.upload-button:hover {
    text-decoration-line: underline;
}

#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.DraftEditor-root {
    font-size: 14px;
}

.hover-zoom-background:hover .cover-image,
.hover-zoom-background:focus .cover-image {
    transition: all 1s;
    transform: scale(1.2);
}

.webcam-container {
    position: relative;
}

.camera-button-overlay {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.webcam-container:hover .camera-button-overlay {
    opacity: 1;
}
